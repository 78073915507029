import {
  en as vuetifyEn,
  hu as vuetifyHu,
  de as vuetifyDe,
} from "vuetify/locale";

export default defineI18nConfig(() => ({
  legacy: false,
  globalInjection: true,
  locale: "hu",
  fallbackLocale: "en",
  messages: {
    en: {
      Home: "Home",
      $vuetify: vuetifyEn,
      OnlineOrder: "Online Order",
      Contact: "Contact Us",
      ContactUs: "Contact Us",
      Name: "Name",
      Email: "Email",
      Message: "Message",
      sending: "Send",
      Address: "Address",
      Phone: "Phone",
      Social: "Social",
      Office: "Office",
      allRights: "All rights reserved.",
      msgSent: "Message successfully sent!",
      msgNotSent: "The message could not be sent!",
      facts: "Manufacturing Data and Results",
      PoultryProcessing: "Poultry Processing Ltd",
      required: "Required field!",
      wrongMail: "E-mail must be valid!",
      switchLang: "Switch to English language",
      ShowMore: "Show More ...",
      PrivacyPolicy: "Privacy Policy and Terms",
      backBtn: "Back to Homepage",
      ContactAgree:
        "I agree that my data will be stored to process this request and future follow-up communications. I can request the deletion of my data at any time.",
      Lastname: "Vezetéknév",
      Firstname: "Keresztnév",
      Birthplace: "Születési hely",
      Birthday: "Születési dátum",
      MotherName: "Anyja neve",
      ShortReason: "A bejelentés okának rövid ismertetése",
      EventTime: "Az esemény időpontja",
      EvidenceFiles:
        "A visszaélés tényét alátámasztó bizonyítékok megjelölése (amennyiben vannak)",
      LongReason:
        "A visszaélés részletes leírása (a bejelentés alapjául szolgáló tevékenység, szervezeti egység, személy(ek), időpont)",
      OtherNotice: "Egyéb megjegyzések",
      AbuseReport: "Complaint Report",
      QualityPolicy: "Quality Policy",
      MoreInfo: "more details",
      QADear: "Dear Partner!",
      QAWelcome:
        "We are working very hard to provide you with the best possible service from our company. <strong>Your opinion is important to us, so please answer the following few questions</strong> so that our further developments can meet your satisfaction. Filling out the survey will only take a few minutes. Thank you for taking this time to provide your feedback.",
      QARatingMsg:
        "<br />Please rate the following questions on a scale of 1 to 4 <br />(1 being not satisfied, 4 being very satisfied)",
      QARatingMsg6:
        "<br />Please rate the following questions on a scale of 1 to 6 <br />(1 being not satisfied, 6 being very satisfied)",
      QARatingMsg2: "1 being not satisfied, 4 being very satisfied",
      QARatingMsg26: "1 being not satisfied, 6 being very satisfied",
      QARatingErr:
        "Please rate the following questions on a scale of 1 to 4 (1 being not satisfied, 4 being very satisfied)",
      QAExplainMsg: "Please elaborate on your opinion in a few sentences.",
      ThankYou: "THANK YOU!",
    },
    hu: {
      Home: "Kezdőlap",
      $vuetify: vuetifyHu,
      OnlineOrder: "Online Rendelés",
      Contact: "Kapcsolat",
      ContactUs: "Kapcsolatfelvétel",
      Name: "Név",
      Email: "Email",
      Message: "Üzenet",
      sending: "Küldés",
      Address: "Cím",
      Phone: "Telefon",
      Social: "Társadalmi média",
      Office: "Iroda",
      allRights: "Minden jog fenntartva.",
      msgSent: "Üzenet sikeresen elküldve!",
      msgNotSent: "Az üzenetet nem sikerült elküldeni!",
      facts: "Gyártási adatok és eredmények",
      PoultryProcessing: "Baromfifeldolgozó Kft",
      required: "Kötelező mező!",
      wrongMail: "Érvényes e-mail cím szükséges!",
      switchLang: "Váltás magyar nyelvre",
      ShowMore: "További termékek ...",
      PrivacyPolicy: "Adatvédelmi irányelvek",
      backBtn: "Vissza a honlapra",
      ContactAgree:
        "Egyetértek adataim tárolásával a kérelem feldolgozásához és a későbbi további kommunikációhoz. Bármikor kérhetem az adataim törlését.",
      Lastname: "Vezetéknév",
      Firstname: "Keresztnév",
      Birthplace: "Születési hely  (Opcionális)",
      Birthday: "Születési dátum  (Opcionális)",
      MotherName: "Anyja neve  (Opcionális)",
      ShortReason: "A bejelentés okának rövid ismertetése",
      EventTime: "Az esemény időpontja",
      EvidenceFiles:
        "A visszaélés tényét alátámasztó bizonyítékok megjelölése (amennyiben vannak)",
      LongReason:
        "A visszaélés részletes leírása (a bejelentés alapjául szolgáló tevékenység, szervezeti egység, személy(ek), időpont)",
      OtherNotice: "Egyéb megjegyzések",
      AbuseReport: "Panaszbejelentés",
      QualityPolicy: "Minőségpolitika",
      MoreInfo: "tovább",
      error: { Min8Chars: "Minimum 8 karakter megadása kötelező!" },
      QADear: "Kedves Partnerünk!",
      QAWelcome:
        "Nagyon sokat dolgozunk azért, hogy Önök a lehető legjobb kiszolgálást kapják cégünktől. <strong>Fontos az Ön véleménye számunkra, ezért kérjük, hogy válaszoljon az alábbi néhány kérdésre</strong>, hogy a további fejlesztéseink az Ön megelégedésére szolgáljanak. A kérdőív kitöltése mindössze néhány percet vesz igénybe. Köszönjük, ha rászánja ezt a néhány percet az értékelésre.",
      QARatingMsg:
        "<br />Kérjük, hogy 1-től 4-ig terjedő skálán értékelje az alábbi kérdéseket <br />(1-es nem elégedett, 2-es kevésbé elégedett, 3-as elégedett, 4-es nagyon elégedett)",
      QARatingMsg6:
        "<br />Kérjük, hogy 1-től 6-ig terjedő skálán értékelje az alábbi kérdéseket <br />(1-es nem elégedett, 6-os nagyon elégedett)",
      QARatingMsg2: "1-es nem elégedett, 4-es nagyon elégedett",
      QARatingMsg26: "1-es nem elégedett, 6-es nagyon elégedett",
      QARatingErr:
        "Kérjük, hogy 1-től 4-ig terjedő skálán értékelje az alábbi kérdéseket (1-es nem elégedett, 4-es nagyon elégedett)",
      QAExplainMsg: "Kérjük, fejtse ki véleményét néhány mondatban.",
      ThankYou: "KÖSZÖNJÜK!",
    },
    de: {
      Home: "Startseite",
      $vuetify: vuetifyDe,
      OnlineOrder: "Online-Bestellung",
      Contact: "Kontakt",
      ContactUs: "Kontaktiere uns",
      Name: "Name",
      Email: "E-Mail",
      Message: "Nachricht",
      sending: "Senden",
      Address: "Adresse",
      Phone: "Telefon",
      Social: "Soziale Medien",
      Office: "Büro",
      allRights: "Alle Rechte vorbehalten.",
      msgSent: "Nachricht erfolgreich gesendet!",
      msgNotSent: "Die Nachricht konnte nicht gesendet werden!",
      facts: "Produktionsdaten und Ergebnisse",
      PoultryProcessing: "Geflügelverarbeitung GmbH",
      required: "Pflichtfeld!",
      wrongMail: "Gültige E-Mail-Adresse erforderlich!",
      switchLang: "Zur deutschen Sprache wechseln",
      ShowMore: "Weitere Produkte anzeigen ...",
      PrivacyPolicy: "Datenschutzrichtlinie",
      backBtn: "Zurück zur Startseite",
      ContactAgree:
        "Ich bin damit einverstanden, dass meine Daten zur Bearbeitung dieser Anfrage und für weitere Kommunikation gespeichert werden. Ich kann jederzeit die Löschung meiner Daten beantragen.",
      Lastname: "Vezetéknév",
      Firstname: "Keresztnév",
      Birthplace: "Születési hely",
      Birthday: "Születési dátum",
      MotherName: "Anyja neve",
      ShortReason: "A bejelentés okának rövid ismertetése",
      EventTime: "Az esemény időpontja",
      EvidenceFiles:
        "A visszaélés tényét alátámasztó bizonyítékok megjelölése (amennyiben vannak)",
      LongReason:
        "A visszaélés részletes leírása (a bejelentés alapjául szolgáló tevékenység, szervezeti egység, személy(ek), időpont)",
      OtherNotice: "Egyéb megjegyzések",
      AbuseReport: "Beschwerdemeldung",
      QualityPolicy: "Qualitätspolitik",
      MoreInfo: "Weitere Informationen",
      QADear: "Liebe Partner!",
      QAWelcome:
        "Wir arbeiten hart daran, Ihnen den bestmöglichen Service von unserem Unternehmen zu bieten. <strong>Ihre Meinung ist uns wichtig, daher bitten wir Sie, die folgenden Fragen zu beantworten</strong>, damit unsere weiteren Entwicklungen zu Ihrer Zufriedenheit beitragen können. Das Ausfüllen des Fragebogens dauert nur wenige Minuten. Vielen Dank, dass Sie sich die Zeit für diese Bewertung nehmen.",
      QARatingMsg:
        "<br />Bitte bewerten Sie die folgenden Fragen auf einer Skala von 1 bis 4 <br />(1 bedeutet nicht zufrieden, 4 bedeutet sehr zufrieden)",
      QARatingMsg6:
        "<br />Bitte bewerten Sie die folgenden Fragen auf einer Skala von 1 bis 6 <br />(1 bedeutet nicht zufrieden, 6 bedeutet sehr zufrieden)",
      QARatingMsg2: "1 bedeutet nicht zufrieden, 4 bedeutet sehr zufrieden",
      QARatingMsg26: "1 bedeutet nicht zufrieden, 6 bedeutet sehr zufrieden",
      QARatingErr:
        "Bitte bewerten Sie die folgenden Fragen auf einer Skala von 1 bis 4 (1 bedeutet nicht zufrieden, 4 bedeutet sehr zufrieden)",
      QAExplainMsg: "Bitte erläutern Sie Ihre Meinung in einigen Sätzen.",
      ThankYou: "DANKE!",
    },
    sk: {
      OnlineOrder: "Online Objednávka",
      Contact: "Kontakt",
      ContactUs: "Kontaktujte nás",
      Name: "Meno",
      Email: "Email",
      Message: "Správa",
      sending: "Odoslať",
      Address: "Adresa",
      Phone: "Telefón",
      Social: "Sociálne siete",
      Office: "Kancelária",
      allRights: "Všetky práva vyhradené.",
      msgSent: "Správa úspešne odoslaná!",
      msgNotSent: "Správu sa nepodarilo odoslať!",
      facts: "Výrobné údaje a výsledky",
      PoultryProcessing: "Poultry Processing s.r.o.",
      required: "Povinné pole!",
      wrongMail: "E-mail musí byť platný!",
      switchLang: "Prepnúť na slovenský jazyk",
      ShowMore: "Zobraziť viac ...",
      PrivacyPolicy: "Pravila o privatnosti",
      backBtn: "Späť na Domovskú stránku",
      ContactAgree:
        "Súhlasím s tým, že moje údaje budú uchované na účely spracovania tejto žiadosti a budúcich komunikácií. Môžem požiadať o vymazanie mojich údajov kedykoľvek.",
      Lastname: "Vezetéknév",
      Firstname: "Keresztnév",
      Birthplace: "Születési hely",
      Birthday: "Születési dátum",
      MotherName: "Anyja neve",
      ShortReason: "A bejelentés okának rövid ismertetése",
      EventTime: "Az esemény időpontja",
      EvidenceFiles:
        "A visszaélés tényét alátámasztó bizonyítékok megjelölése (amennyiben vannak)",
      LongReason:
        "A visszaélés részletes leírása (a bejelentés alapjául szolgáló tevékenység, szervezeti egység, személy(ek), időpont)",
      OtherNotice: "Egyéb megjegyzések",
      AbuseReport: "Visszaélés-bejelentés",
      QualityPolicy: "Minőségpolitika",
      MoreInfo: "viac podrobností",
    },
    hr: {
      OnlineOrder: "Narudžba online",
      Contact: "Kontaktirajte nas",
      ContactUs: "Kontaktirajte nas",
      Name: "Ime",
      Email: "Email",
      Message: "Poruka",
      sending: "Pošalji",
      Address: "Adresa",
      Phone: "Telefon",
      Social: "Društvene mreže",
      Office: "Ured",
      allRights: "Sva prava pridržana.",
      msgSent: "Poruka uspješno poslana!",
      msgNotSent: "Poruku nije moguće poslati!",
      facts: "Proizvodni podaci i rezultati",
      PoultryProcessing: "Poultry Processing d.o.o.",
      required: "Obavezno polje!",
      wrongMail: "Email mora biti valjan!",
      switchLang: "Prebacite na hrvatski jezik",
      ShowMore: "Prikaži više ...",
      PrivacyPolicy: "Zásady ochrany osobných údajov",
      backBtn: "Povratak na Početnu stranicu",
      ContactAgree:
        "Slažem se da će moji podaci biti pohranjeni kako bi se obradio ovaj zahtjev i buduće komunikacije. U svakom trenutku mogu zatražiti brisanje svojih podataka.",
      Lastname: "Vezetéknév",
      Firstname: "Keresztnév",
      Birthplace: "Születési hely",
      Birthday: "Születési dátum",
      MotherName: "Anyja neve",
      ShortReason: "A bejelentés okának rövid ismertetése",
      EventTime: "Az esemény időpontja",
      EvidenceFiles:
        "A visszaélés tényét alátámasztó bizonyítékok megjelölése (amennyiben vannak)",
      LongReason:
        "A visszaélés részletes leírása (a bejelentés alapjául szolgáló tevékenység, szervezeti egység, személy(ek), időpont)",
      OtherNotice: "Egyéb megjegyzések",
      AbuseReport: "Visszaélés-bejelentés",
      QualityPolicy: "Minőségpolitika",
      MoreInfo: "više detalja",
    },
    ro: {
      OnlineOrder: "Comandă Online",
      Contact: "Contactează-ne",
      ContactUs: "Contactează-ne",
      Name: "Nume",
      Email: "Email",
      Message: "Mesaj",
      sending: "Trimite",
      Address: "Adresă",
      Phone: "Telefon",
      Social: "Social",
      Office: "Birou",
      allRights: "Toate drepturile rezervate.",
      msgSent: "Mesaj trimis cu succes!",
      msgNotSent: "Mesajul nu a putut fi trimis!",
      facts: "Date și Rezultate de Producție",
      PoultryProcessing: "Procesare Pui SRL",
      required: "Câmp obligatoriu!",
      wrongMail: "Adresa de email trebuie să fie validă!",
      switchLang: "Comutați la limba română",
      ShowMore: "Afișează mai mult ...",
      PrivacyPolicy: "Politica de confidențialitate",
      backBtn: "Înapoi la Pagina Principală",
      ContactAgree:
        "Sunt de acord ca datele mele să fie stocate pentru a procesa această solicitare și pentru comunicări ulterioare. În orice moment pot solicita ștergerea datelor mele.",
      Lastname: "Vezetéknév",
      Firstname: "Keresztnév",
      Birthplace: "Születési hely",
      Birthday: "Születési dátum",
      MotherName: "Anyja neve",
      ShortReason: "A bejelentés okának rövid ismertetése",
      EventTime: "Az esemény időpontja",
      EvidenceFiles:
        "A visszaélés tényét alátámasztó bizonyítékok megjelölése (amennyiben vannak)",
      LongReason:
        "A visszaélés részletes leírása (a bejelentés alapjául szolgáló tevékenység, szervezeti egység, személy(ek), időpont)",
      OtherNotice: "Egyéb megjegyzések",
      AbuseReport: "Visszaélés-bejelentés",
      QualityPolicy: "Minőségpolitika",
      MoreInfo: "mai multe detalii",
    },
  },
}));
